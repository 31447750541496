import { Injectable } from '@angular/core';
import CustomStore from 'devextreme/data/custom_store';
import { LoadOptions } from 'devextreme/data/load_options';
import { Constants, SearchOperations } from 'src/app/app.constants';
import { OptionsSource } from 'src/app/core/core.models';
import { HttpRequestType, RequestService } from 'src/app/core/request.service';
import { BaseService } from 'src/app/shared/base/base.service';
import { OfferedProduct, PointOfSaleType, Product, ProductClassificationType, ProductTemplateAttributeByMerch, SearchMap, ShopParams } from './offered-products.models';

@Injectable({
  providedIn: 'root'
})
export class OfferedProductsService extends BaseService {
  public keyValueJoiner = '__-_';

  constructor(protected requestService: RequestService) {
    super(requestService);
  }

  getProducts(options: any, onSuccess: Function) {
    this.requestService.createRequest(HttpRequestType.Post, `${Constants.contextPath}/offered-products/b2b`, options, null, (response) => {
      onSuccess(response);
    })
  }

  getOfferedProduct(id: any, onSuccess: Function) {
    this.getById(`${Constants.contextPath}/offered-products/b2b`, id, (response: OfferedProduct) => {
      onSuccess(response);
    })
  };

  getProduct(id: any): Promise<Product> {
    return new Promise(resolve => {
      this.getById(`${Constants.contextPath}/products`, id, (response: Product) => {
        resolve(response);
      })
    });
  };

  getProductsOptionsByPointOfSale(pointOfSaleName: string) {
    let url: string = `${Constants.contextPath}/offered-products/b2b`;
    let options: ShopParams = new ShopParams();
    options.pageSize = this.pageSize;
    options.searchMap = new SearchMap();
    options.searchMap.pointOfSaleName = pointOfSaleName;
    return {
      paginate: true,
      pageSize: this.pageSize,
      store: new CustomStore({
        key: 'id',
        load: (loadOptions: LoadOptions) => {
          options.page = loadOptions.skip ? loadOptions.skip : 0;
          if (loadOptions.filter != null)
            options.searchMap = this.getFilterValue(loadOptions.filter);
          return this.requestService.createRequestAsPromise(HttpRequestType.Patch, url, options, null, (response) => {
            return response.content;
          });
        },
        byKey: (id: number | string | any) => {
          if (id != null)
            if (typeof id === 'string' || typeof id === 'number')
              return this.getByIdAsPromise(url, id, (response: any) => {
                return response;
              });
            else if (typeof id === 'object')
              return this.getByIdAsPromise(url, id.id, (response: any) => {
                return response;
              });
        }
      })
    };
  }

  protected getFilterValue(filter: any): SearchMap {
    let searchMap = new SearchMap();
    let filterArray = filter

    if (filter.indexOf('and') == -1 && filter.indexOf('or') == -1)
      filterArray = [[filter[0], filter[1], filter[2]]];
    else if (filter.indexOf('or') != -1)
      filterArray = [[filter[0][0], '<>', filter.filterValue]];

    for (let filterItem of filterArray)
      if (Array.isArray(filterItem))
        if (filterItem.indexOf('and') == -1 && filterItem.indexOf('or') == -1) {
          if (filterItem[0] == 'product.code')
            searchMap.code = filterItem[2];
          if (filterItem[0] == 'product.name')
            searchMap.name = filterItem[2];
        }

    return searchMap
  }

  public getProductClassificationOptions(type: ProductClassificationType) {
    return this.getOptionsByMultipleFieldsFilter(this.determineProductClassificationUrl(type), 'code', ['code', 'name'], (response) => {
      if (Array.isArray(response))
        for (let p of response)
          p.displayName = `${p.code} ${p.name}`;
      else
        response.displayName = `${response.code} ${response.name}`;
      return response;
    });
  }

  public getProductClassificationOptionsByCode(type: ProductClassificationType) {
    return this.getProductClassificationByCode(this.determineProductClassificationUrl(type));
  }

  public determineProductClassificationUrl(type: ProductClassificationType): string {
    switch (type) {
      case ProductClassificationType.ASSORTMENT:
        return `${Constants.contextPath}/product-assortments`;
      case ProductClassificationType.BRAND:
        return `${Constants.contextPath}/product-brands`;
      case ProductClassificationType.CATEGORY:
        return `${Constants.contextPath}/product-categories`;
      case ProductClassificationType.GROUP:
        return `${Constants.contextPath}/product-groups`;
      case ProductClassificationType.MERCHANDISE_GROUP:
        return `${Constants.contextPath}/product-merchandise-groups`;
      case ProductClassificationType.POINT_OF_SALE:
        return `${Constants.contextPath}/point-of-sales`;
    }
  }

  getAvailableBrandsByMerchCode(merchCode: string) {
    return new Promise(resolve => {
      this.getAll(`${this.determineProductClassificationUrl(ProductClassificationType.BRAND)}/by-merchandise-groups/${merchCode}/${PointOfSaleType[PointOfSaleType.WHOLESALE]}`, (res) => {
        resolve(res)
      })
    })
  }

  getAvailableTemplatesAttributesByMerchCode(merchCode: string) {
    return new Promise(resolve => {
      this.getAll(`${Constants.contextPath}/product-template-attributes/by-merchandise-groups/${merchCode}/${PointOfSaleType[PointOfSaleType.WHOLESALE]}`, (res: ProductTemplateAttributeByMerch) => {
        let groupedArray = this.groupArray(res, 'templateAttributeId', 'templateAttributeName');
        Object.entries(groupedArray).forEach(([key, value]) => groupedArray[key] = this.joinIdsOfSameTemplateAttributeValues(value))
        resolve(groupedArray)
      })
    })
  }

  private groupArray(array: any, key: string, secondKey?: string) {
    if(array.length < 1) return []
    return array.reduce((r, a) => {
      a['productTemplateAttributeValue'] = a['productTemplateAttributeValue'].toLowerCase().trim();
      let estKey = secondKey ? a[secondKey] + this.keyValueJoiner + a[key] : a[key];
      r[estKey] = r[estKey] || [];
      r[estKey].push(a);
      return r;
    }, []);
  }

  private joinIdsOfSameTemplateAttributeValues(array: any) {
    let out = this.groupArray(array, 'productTemplateAttributeValue')
    return out
  }

  private getProductClassificationByCode(url: string): OptionsSource {
    let fields: string[] = ['code', 'name']
    let sortBy: string = 'code';
    return {
      paginate: true,
      pageSize: this.pageSize,
      store: new CustomStore({
        key: 'id',
        load: (loadOptions: any) => {
          let filter = '';
          if (sortBy != null)
            filter = `&sortBy=${sortBy}&sortOrder=asc`;
          if (loadOptions.searchExpr && loadOptions.searchValue) {
            let fieldsFilter = '';

            if (fields != null && fields.length > 0) {
              let counter = 0;
              for (let field of fields) {
                let OR = `${SearchOperations.KEY_OR}`;
                if (counter == 0)
                  OR = '';
                fieldsFilter = fieldsFilter + `${OR}${SearchOperations.LEFT_PARENTHESES_READABLE}${field}${SearchOperations.LIKE}${loadOptions.searchValue}${SearchOperations.RIGHT_PARENTHESES_READABLE}`;
                counter++;
              }
            }

            filter = `&${SearchOperations.KEY_SEARCH}=${SearchOperations.LEFT_PARENTHESES_READABLE}${fieldsFilter}${SearchOperations.RIGHT_PARENTHESES_READABLE}&sortBy=${sortBy}&sortOrder=asc`;

          }

          return this.getAllAsPromise(url, loadOptions.skip ? loadOptions.skip : 0, filter, null, (response: any) => {
            let data = response;
            if (response.content != null)
              data = response.content;

            for (let p of data)
              p.displayName = `${p.code} ${p.name}`;

            return data;
          });
        },
        byKey: (code: string) => {
          return this.requestService.createRequestAsPromise(HttpRequestType.Get, `${url}/list?${SearchOperations.KEY_SEARCH}=${SearchOperations.LEFT_PARENTHESES_READABLE}code${SearchOperations.EQUALITY}${code}${SearchOperations.RIGHT_PARENTHESES_READABLE}`, null, null, (response: any) => {
            let pmg = response != null && response.length > 0 ? response[0] : null;
            if (pmg)
              pmg.displayName = `${pmg.code} ${pmg.name}`;
            return pmg;
          });
        }
      })
    };
  } 

  removeFields(params) {
    for (let [key, value] of Object.entries(params))
      if (value == null)
        delete params[key];
      else
        for (let [k, v] of Object.entries(value))
          if (v == null)
            delete value[k];
    return params;
  }
}
