<dx-drop-down-box #dropDownBox [placeholder]="'DEV-EXTREME.Select' | translate" *ngIf="!readOnly">
    <dx-data-grid #grid [dataSource]="dataSource" class="pb-3" [height]="height" (onCellPrepared)="onCellPrepared($event)" (onSelectionChanged)="onSelectionChanged($event)" [showColumnLines]="false" [showRowLines]="true" [showBorders]="true" [rowAlternationEnabled]="true" [columnAutoWidth]="true" [selection]="{ mode: 'single' }">
        <dxo-load-panel [enabled]="true"></dxo-load-panel>
        <dxo-scrolling mode="virtual"></dxo-scrolling>
        <dxo-paging [pageSize]="pageSize"></dxo-paging>
        
        <dxo-remote-operations [filtering]="true" [paging]="true" [sorting]="true"></dxo-remote-operations>
        <dxo-filter-row [visible]="true"></dxo-filter-row>

        <dxi-column dataField="product.code" [filterOperations]= "['=']" dataType="string" [allowFiltering]="true" [allowSorting]="false" [caption]="'SHARED.CODE' | translate"></dxi-column>
        <dxi-column dataField="product.name" [filterOperations]= "['=']" dataType="string" [allowFiltering]="true" [allowSorting]="false" [caption]="'SHARED.NAME' | translate"></dxi-column>
        <dxi-column dataField="regularPrice" [format]="'#0.00'" dataType="number" [allowFiltering]="false" [allowSorting]="false" [caption]="'CUSTOMER_ORDERS.REGULAR_PRICE' | translate"></dxi-column>
    </dx-data-grid>
</dx-drop-down-box>