import { Route } from '@angular/router';
import { InsideGuard } from 'src/app/guards';
import { LayoutComponent } from '../layout/layout.component';
import { LogoutComponent } from './logout/logout.component';

export const routes: Route[] = [
  {
      path: '',
      component: LayoutComponent,
      canActivate: [InsideGuard],
      children: [
          {
              path: '', redirectTo: 'dashboard', pathMatch: 'full'
          },
          {
              path: 'dashboard',
              loadChildren: () => import('../routes/dashboard/dashboard.module').then(m => m.DashboardModule)
          },
          {
              path: 'users',
              data: {
                  authorities: ['R_USR']
              },
              loadChildren: () => import('../routes/users/users.module').then(m => m.UsersModule)
          },
          {
              path: 'profile',
              loadChildren: () => import('../routes/profile/profile.module').then(m => m.ProfileModule)
          },
          {
              path: 'delivery-places',
              loadChildren: () => import('../routes/delivery-places/delivery-places.module').then(m => m.DeliveryPlacesModule)
          },
          {
              path: 'offered-products',
              loadChildren: () => import('../routes/offered-products/offered-products.module').then(m => m.OfferedProductsModule)
          },
          {
              path: 'orders',
              loadChildren: () => import('../routes/orders/orders.module').then(m => m.OrdersModule)
          },
          {
              path: 'logout', component: LogoutComponent, pathMatch: 'full'
          }
      ]
  }
];


// {
//     path: '',
//     component: LayoutComponent,
//     canActivate: [InsideGuard],
//     children: [
//         {
//             path: '', redirectTo: 'dashboard', pathMatch: 'full'
//         },
//         ...dashboardRoutes,
//         ...usersRoutes,
//         ...profileRoutes,
//         {
//             path: 'logout', component: LogoutComponent, pathMatch: 'full'
//         }
//     ]
// }