import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { CompanyConfiguration } from '../inside-layout/routes/profile/profile.models';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {
  private readonly SHOP_VIEW_KEY = 'selected_shop_view';
  logo: CompanyConfiguration;
  selectedShopView: 'compact' | 'card' | 'list' = 'card';
  menuSidebarOpened: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  filterSidebarOpened: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  stylingMode: string = 'underlined'; //'outlined'

  constructor(private storageService: StorageService) {
    this.selectedShopView = storageService.get(this.SHOP_VIEW_KEY) ? storageService.get(this.SHOP_VIEW_KEY) : 'card';
  }

  setMenuOpenedValue(value: boolean) {
    this.menuSidebarOpened.next(value);
    this.filterSidebarOpened.next(false);
  }

  setFilterOpenedValue(value: boolean) {
    this.filterSidebarOpened.next(value);
    this.menuSidebarOpened.next(false);
  }

  setSelectedShopView(view: 'compact' | 'card' | 'list') {
    this.selectedShopView = view;
    this.storageService.save(this.SHOP_VIEW_KEY, view);
  }
}
