import { Component, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import CustomStore from 'devextreme/data/custom_store';
import { of } from 'rxjs';
import { SearchOperations } from '../../app.constants';
import { AuthService } from '../../core/auth.service';
import { LoadingService } from '../../core/loading.service';
import { UtilityService } from '../../core/utility.service';
import { DialogService, Dialog } from '../../core/dialog.service';
import { AlertService } from '../../core/alert.service';
import { RouteAuthorities } from '../base/base.models';
import { BaseService } from './base.service';
import { RequestService } from 'src/app/core/request.service';
import { DxDataGridComponent } from 'devextreme-angular';

declare let $: any;

@Component({
    template: ''
})
export class BaseListComponent {
    @ViewChild('table', { static: false }) table: DxDataGridComponent;
    public url: string;
    public dataSource: CustomStore;
    public columns: any[];
    public totalElements: number;
    public selectedOperation: string = 'contains';
    public authorities: RouteAuthorities;

    public creatable: boolean = false;
    public editable: boolean = false;
    public deletable: boolean = false;
    public activatable: boolean = false;

    public data: any[];
    protected totalCount: number;

    private skip: number;
    public filter: string;
    public sort: string;

    public gridStateKey: string;
    public lookupColumns: Array<string> = [];

    constructor(
        public authService: AuthService,
        public loadingService: LoadingService,
        public baseService: BaseService,
        public dialogService: DialogService,
        public alertService: AlertService,
        public utilityService: UtilityService,
        public requestService: RequestService,
        public translateService: TranslateService
    ) {
        this.authorities = new RouteAuthorities(authService, alertService, null, null, null, null);
    }

    public delete(id: string): void {
        let dialog = new Dialog('SHARED.DELETE_QUESTION', () => {
            this.baseService.delete(this.url, id, () => {
                this.alertService.success('SHARED.DELETE_SUCCESS');
                this.getGridData(null, true);
            }, (error) => {
                this.alertService.error(error, true);
            });
        });

        this.dialogService.show(dialog);
    }

    public changeStatus(id: number | string, active: boolean): void {
        let dialogData = new Dialog(active ? 'SHARED.ACTIVATE_QUESTION' : 'SHARED.DEACTIVATE_QUESTION', () => {
            this.baseService.changeActive(this.url, id, active, () => {
                this.alertService.success(active ? 'SHARED.ACTIVATE_SUCCESS' : 'SHARED.DEACTIVATE_SUCCESS');
                this.getGridData(null, true);
            });
        });

        this.dialogService.show(dialogData);
    }

    ngOnInit() {
        this.getGridData();
    }

    public translateKeys(translations: string[], onSuccess: Function) {
        this.translateService.get(translations).subscribe((translation: string[]) => {
            if (onSuccess != null)
                onSuccess(translation);
        });
    }

    protected getGridData(filterValue: string = null, force: boolean = false): void {
        this.dataSource = new CustomStore({
            key:   'id',
            load:  (loadOptions: any) => {
                return this.loadGridData(loadOptions, filterValue, force);
            },
            byKey: () => {
                return null;
            }
        });
    }

    protected loadGridData(loadOptions: any, initialFilterValue: string, force: boolean): any {
        let filter = '', sort = '', filterValue = '';

        if (loadOptions.filter != null)
            filterValue = this.getFilterValue(loadOptions.filter);

        if (initialFilterValue != null && filterValue != '')
            filter = `&${SearchOperations.KEY_SEARCH}=${SearchOperations.LEFT_PARENTHESES_READABLE}${ initialFilterValue }${SearchOperations.SPLIT_SINGLE_OPERATION}${ filterValue }${SearchOperations.RIGHT_PARENTHESES_READABLE}`;
        else if (initialFilterValue != null)
            filter = `&${SearchOperations.KEY_SEARCH}=${SearchOperations.LEFT_PARENTHESES_READABLE}${ initialFilterValue }${SearchOperations.RIGHT_PARENTHESES_READABLE}`;
        else if (filterValue != '')
            filter = `&${SearchOperations.KEY_SEARCH}=${SearchOperations.LEFT_PARENTHESES_READABLE}${ filterValue }${SearchOperations.RIGHT_PARENTHESES_READABLE}`;

        if (loadOptions.sort != null && loadOptions.sort[0] != null)
            sort = `&sortBy=${ loadOptions.sort[0].selector }&sortOrder=${ loadOptions.sort[0].desc ? 'desc' : 'asc' }`;

        if (force || loadOptions.skip != this.skip || filter != this.filter || sort != this.sort) {
            this.skip = loadOptions.skip;
            this.filter = filter;
            this.sort = sort;

            return this.baseService.getAllAsPromise(this.url, loadOptions.skip, filter, sort, (response: any) => {
                for(let d of response.content) {
                    if(d.dateCreated)
                        d.dateCreated = this.modifyDate(d.dateCreated);
                    if(d.dateModified)
                        d.dateModified = this.modifyDate(d.dateModified);
                }
                this.data = response.content;
                this.totalCount = response.totalElements;

                
                if (this.gridStateKey != null && this.lookupColumns)
                    this.saveGridStateBase(this.table.instance.state(), this.gridStateKey, this.lookupColumns);

                return {
                    data:       response.content,
                    totalCount: response.totalElements
                };
            }, (error: any) => {
                this.requestService.handleError(error);
                return {
                    data:       [],
                    totalCount: 0
                };
            });
        }

        return of(null).pipe().toPromise().then(_ => {
            return {
                data:       this.data,
                totalCount: this.totalCount
            };
        });
    }    

    protected getFilterValue(filter: any): string {
        let filterValue = '', filterArray = filter, filterOperators = {
            '=':           '::',
            '<>':          '!!',
            '>':           '>>',
            '>=':          '~:',
            '<':           '<<',
            '<=':          ':~',
            'contains':    '~~',
            'notcontains': '~!',
            'startswith':  '~<',
            'endswith':    '~>'
        };

        if (filter.indexOf('and') == -1 && filter.indexOf('or') == -1)
            filterArray = [[filter[0], filter[1], filter[2]]];
        else if (filter.indexOf('or') != -1)
            filterArray = [[filter[0][0], '<>', filter.filterValue]];

        for (let filterItem of filterArray)
            if (Array.isArray(filterItem))
                if (filterItem.indexOf('and') == -1 && filterItem.indexOf('or') == -1) {
                    let value = '';

                    if (Object.prototype.toString.call(filterItem[2]) == '[object Date]')
                        value = this.utilityService.formatDateToISO(filterItem[2]);
                    else if(filterItem[0].toLowerCase().includes("code") && filterItem[2].length > 1)
                        value = filterItem[2];
                    else if ((Object.prototype.toString.call(filterItem[2]) == '[object String]' && filterItem[2].length > 2) || Object.prototype.toString.call(filterItem[2]) != '[object String]')
                        value = filterItem[2];

                    if (value != '')
                        filterValue += `${ filterItem[0].replace('.', SearchOperations.SPLIT_JOIN_OPERATOR) }${ filterOperators[filterItem[1]] }${ value }${ SearchOperations.SPLIT_SINGLE_OPERATION }`;
                } else
                    filterValue += `${ this.getFilterValue(filterItem) }${ SearchOperations.SPLIT_SINGLE_OPERATION }`;

        return filterValue.substr(0, filterValue.length - 2);
    }

    public loadGridStateBase(gridStateKey: string, lookupColumns: Array<string> = []): any {
        let state: any = JSON.parse(localStorage.getItem(gridStateKey));

        setTimeout(() => {
            if (state != null)
                for (let column of state.columns) {
                    if (lookupColumns.indexOf(column.dataField) != -1 && column.filterValueText != null && !column.filterValueText.startsWith('(') && !column.filterValueText.endsWith(')'))
                        this.setFilterText(column.visibleIndex, column.filterValueText);
                }
        }, 2500);

        return state;
    }

    public saveGridStateBase(state: any, gridStateKey: string, lookupColumns: Array<string> = []): void {
        if (state != null)
            for (let column of state.columns)
                if (lookupColumns.indexOf(column.dataField) != -1)
                    column.filterValueText = this.getFilterText(column.visibleIndex);

        localStorage.setItem(gridStateKey, JSON.stringify(state));
    }

    private getFilterText(index: number): string {
        let input = $(`.dx-datagrid-filter-row td:eq(${index})`).find('.dx-texteditor-input');
        return input != null && input[0] != null ? $(input[0]).val() : null;
    }

    private setFilterText(index: number, text: string): void {
        let input = $(`.dx-datagrid-filter-row td:eq(${index})`).find('.dx-texteditor-input');
        if (input != null && input[0] != null)
            $(input[0]).val(text);
    }

    public generateGridStateReport() {
        let urlPart = '?page=0&pageSize=99999';
        this.alertService.success(`${this.url}${urlPart}${this.filter}${this.sort}`);
    }
    
    public modifyDate(dateString: string) {
        // let date = new Date(dateString).toLocaleString('en-US', { timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone });
        // return date
        return this.utilityService.formatDateTime(new Date(dateString + 'Z'));
        // return dateString;
    }
}
