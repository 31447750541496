import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import CustomStore from 'devextreme/data/custom_store';
import DataSource from 'devextreme/data/data_source';
import { Constants } from 'src/app/app.constants';
import { HttpRequestType, RequestService } from 'src/app/core/request.service';
import { UtilityService } from 'src/app/core/utility.service';
import { BaseService } from '../base/base.service';
import { NotificationPriority, NotificationType, Notification } from './notifications.models';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService extends BaseService {
  private url = `${Constants.contextPath}/notifications`;
  public unreadNotificationsNumber: number = 0;

  constructor(protected requestService: RequestService, private utilityService: UtilityService, private sanitizer: DomSanitizer) {
    super(requestService);
  }

  public getNotifications(tab: NotificationType, selectedPriority: NotificationPriority) {    
    let url = tab.toString() != NotificationType[NotificationType.SEND] ? this.url : `${this.url}/sent`;
    let searchFilter = `&notificationsTypes=${tab}`;
    let searchPriority = selectedPriority ? `&notificationPriorities=${selectedPriority}` : null;
    return new DataSource({
      store: new CustomStore({
        key: 'id',
        load: (loadOptions: any) => {
          if (loadOptions.take)
            return this.loadGridData(loadOptions, url, searchFilter, searchPriority);
        }
      }),
      paginate: true,
      pageSize: 15
    });
  }

  protected loadGridData(loadOptions: any, url: string, searchFilter: string, searchPriority: string): any {
    let skip = loadOptions.skip ? loadOptions.skip : 0;
    return this.getAllAsPromise(url, skip, null, `${searchFilter == null ? '' : searchFilter}${searchPriority == null ? '' : searchPriority}&sortBy=dateCreated`, (response: any) => {
      for(let notification of response.content)
          notification.description = this.sanitizer.bypassSecurityTrustHtml(notification.description)
      return {
        data: response.content,
        totalCount: response.totalElements
      };
    }, (error: any) => {
      this.requestService.handleError(error);
      return {
        data: [],
        totalCount: 0
      };
    });
  }

  public changeReadStatus(id: string, onSuccess: Function) {
    this.requestService.createRequest(HttpRequestType.Patch, `${Constants.contextPath}/notifications/${id}/change-read-status`, null, null, (response: Notification) => {
      onSuccess(response);
    });
  }

  public checkUnreadNotifications() {
    this.getAll(`${Constants.contextPath}/notifications/not-read`, (response) => {
      this.unreadNotificationsNumber = response;
    });
  }

  public updateUnreadNotifications(operation: string, currentNumber: number)
  {
    this.unreadNotificationsNumber = currentNumber;
    if(operation == 'add'){
      this.unreadNotificationsNumber++;
    }
    else if(operation == 'remove'){
      this.unreadNotificationsNumber--;
    }
  }
}
