import { Injectable } from '@angular/core';

import { confirm } from 'devextreme/ui/dialog';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
    providedIn: 'root'
})
export class DialogService {
    constructor(
        private translateService: TranslateService
    ) { }

    public show(dialog: Dialog): void {
        this.translateService.get([dialog.message, 'SHARED.CONFIRMATION']).subscribe((translations: Array<string>) => {
            let messageHtml = `<span class="fa fa-question-circle dialog-icon"></span> ${translations[dialog.message]}`;

            confirm(messageHtml, translations['SHARED.CONFIRMATION']).then((confirmed: boolean) => {
                if (confirmed)
                    dialog.acceptCallback();
                else if (dialog.rejectCallback != null)
                    dialog.rejectCallback();
            });
        });
    }
}

export class Dialog {
    message: string;
    acceptCallback: Function;
    rejectCallback: Function;

    constructor(message: string, acceptCallback: Function, rejectCallback: Function = null) {
        this.message = message;
        this.acceptCallback = acceptCallback;
        this.rejectCallback = rejectCallback;
    }
}
