import { Injectable } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { Router } from '@angular/router';

import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';

@Injectable({
    providedIn: 'root'
})
export class UtilityService {
    constructor(private translateService: TranslateService, private router: Router) { }

    public showError(control: AbstractControl, errorType: string): boolean {
        return control.touched && control.errors && control.errors[errorType];
    }

    public getDefaultDateFormat(): string {
        return 'dd.mm.yy';
    }

    public getDefaultYearRange(): string {
        return '1900:2030';
    }

    public formatDate(date: Date): string {
        if (date == null)
            return '';
        date = new Date(date);
        return date.getDate() + "." + (date.getMonth() + 1) + "." + date.getFullYear()
    }

    public formatDateTime(date: Date): string {
        if (date == null)
            return '';

        date = new Date(date);

        return date.getDate() + "." + (date.getMonth() + 1) + "." + date.getFullYear() + ' ' + this.getHours(date) + ':' + this.getMinutes(date);
    }

    public formatDateTimeToTime(date: Date | string) {
        if (date == null)
            return '';

        date = new Date(date);

        return this.getHours(date) + ':' + this.getMinutes(date);
    }

    public getHours(date) {
        return (date.getHours(date) < 10 ? '0' : '') + date.getHours(date);
    }

    public getMinutes(date) {
        return (date.getMinutes(date) < 10 ? '0' : '') + date.getMinutes(date);
    }

    public formatTime(time: string): string {
        if (/^(?:[01]\d|2[0123]):(?:[012345]\d):(?:[012345]\d)$/.test(time) == false)
            time = this.formatDateTimeToTime(time);
        return time.slice(0, -3);
    }

    public getHoursFromTimeString(time: string): string {
        return time.slice(0, 2);
    }

    public getMinutesFromTimeString(time: string): string {
        return time.slice(3, 5);
    }

    public formatTimeFromDate(date: Date): string {
        if (date == null)
            return '';

        date = new Date(date);

        return `${this.getHours(date)}:${this.getMinutes(date)}`;
    }

    public formatDateToISO(date: any): string {
        if (date == null || date == '')
            return '';

        if (typeof date == 'string')
            return date.substr(6, 4) + '-' + date.substr(3, 2) + '-' + date.substr(0, 2);

        return date.toISOString().substr(0, date.toISOString().length - 1);
    }

    public formatDateToYYYYMMDD(dateOrString: Date | string) {
        let date = new Date(dateOrString);
        return date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate();
    }

    public getNow(addDays?: number): string {
        if (addDays)
            return new Date(new Date(Date.now()).getDate() + addDays).toLocaleString();

        return new Date(Date.now()).toLocaleString();
    }

    public prepareLookupItems(items: any[], valueField: string = null, labelField: string = null, translationPrefix: string = null): any[] {
        if (items.length < 1)
            return [];

        if (translationPrefix == null) {
            items.map(item => ({ value: item[valueField] || item, label: item[labelField] || item }));
            return items;
        } else {
            let translatedItems = [];
            this.translateService.get(items.map(e => `${translationPrefix}${e[labelField]}`)).subscribe((translations: any[]) => {
                for (let item of items)
                    translatedItems.push({ value: item[valueField], label: translations[`${translationPrefix}${item[labelField]}`] }); 
            });
            return translatedItems;
        }
    }

    public prepareLookupItemsCustom(items: any[], enabledItems: any[], valueField: string = null, labelField: string = null, translationPrefix: string = null): any[] {
        if (items.length < 1)
            return [];

        if (translationPrefix == null) {
            items.map(item => ({ value: item[valueField] || item, label: item[labelField] || item }));
            return items;
        } else {
            let translatedItems = [];
            this.translateService.get(items.map(e => `${translationPrefix}${e[labelField]}`)).subscribe((translations: any[]) => {
                for (let item of items){
                    if(enabledItems.findIndex(x=> x.value == item.value ) > -1){
                        translatedItems.push({ value: item[valueField], label: translations[`${translationPrefix}${item[labelField]}`] });
                    }else{
                        translatedItems.push({ value: item[valueField], label: translations[`${translationPrefix}${item[labelField]}`], disabled: [true] });
                    }
                }
            });
            return translatedItems;
        }
    }

    public enumToSelectArray(enumObject: any, isString = false): any[] {
        return Object.keys(enumObject).filter(k => typeof enumObject[k as any] === 'number').map(k => ({ value: !isString ? enumObject[k as any] : k, label: k }));
    }

    public getAllYears(maxYear: number = null, minYear: number = null) {
        let reverse: boolean = false;
        let currentYear = new Date().getFullYear();

        if (maxYear == null) {
            reverse = true;
            maxYear = currentYear + 10;
        }

        if (minYear == null) {
            minYear = currentYear;
        }

        let years = [];
        for (let i = maxYear; i >= minYear; i--)
            years.push({ value: i });


        if (reverse)
            years.reverse();

        return years;
    }

    public generateUUID() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    }

    public formatNotificationDateTime(dateTime: string) {
        if (dateTime == null || dateTime == '')
            return null;

        let date = this.formatDate(new Date(dateTime.substring(0, 10)));
        let time = dateTime.substring(11, 16)

        return { date: date, time: time };
    }

    public isUnique(rows: any[], data: any, uniqueField: string, keyField = 'id'): boolean {
        let dataKeyField = data[keyField] || data['__KEY__'];

        for (let row of rows) {
            let rowKeyField = row[keyField] || row['__KEY__'];

            if (row[uniqueField] == data[uniqueField] && rowKeyField != dataKeyField)
                return false;
        }

        return true;
    }

    public openInNewTab(url: string){
        const targetUrl = this.router.serializeUrl(
          this.router.createUrlTree([url])
        );      
        window.open(targetUrl, '_blank');
    }

    public enumToArray(enumObjecy: any) {
        return Object.keys(enumObjecy)
            .map(key => enumObjecy[key]);
    }

    public formatDateTimeView(date){ // example 15.03.2021. 14:50
        return moment(date).format('DD.MM.yyyy. HH:mm');
    }

    routeContains(value: string){
        return this.router.url.includes(value);
    }

    public thousandSeparator(num: number, fixed = 2) {
        num = num == null ? 0 : num;

        let num_parts = num.toFixed(fixed).toString().split(".");
        num_parts[0] = num_parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        return num_parts.join(",");
    }

    public openCalendar = (event) => {
        event.component.open();
    }
}
