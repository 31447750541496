const users = {
    routerLink: '/users',
    icon: 'people',
    key: 'MENU.ADMINISTRATION.USERS',
    tooltip: 'MENU.ADMINISTRATION.USERS',
    authorities: ['CR_US_PRTNR']
};

const dashboard = {
    routerLink: '/dashboard',
    icon: 'speed',
    key: 'MENU.DASHBOARD.DASHBOARD',
    tooltip: 'MENU.DASHBOARD.DASHBOARD'
};

const deliveryPlaces = {
    routerLink: '/delivery-places',
    icon: 'local_shipping',
    key: 'MENU.PARTNER.DELIVERY_PLACES',
    tooltip: 'MENU.PARTNER.DELIVERY_PLACES',
    authorities: ['R_PRTNR']
};

const offerdProducts = {
    routerLink: '/offered-products',
    icon: 'storefront',
    key: 'MENU.PARTNER.OFFERED_PRODUCTS',
    tooltip: 'MENU.PARTNER.OFFERED_PRODUCTS',
    authorities: ['R_CD']
};

const orders = {
    routerLink: '/orders',
    icon: 'shopping_cart',
    key: 'MENU.PARTNER.ORDERS',
    tooltip: 'MENU.PARTNER.ORDERS',
    authorities: ['R_B2B_ORDR']
};

export interface MenuGroup {
    key?: string;
    tooltip?: string;
    icon?: string;
    routerLink?: string;
    authorities?: string[];
    children?: MenuGroup[];
}

export const Menu: MenuGroup[] = [
    dashboard,
    users,
    deliveryPlaces,
    offerdProducts,
    orders
]
