import { Injectable } from '@angular/core';

import { RequestService, HttpRequestType } from '../../core/request.service';
import { OptionsSource } from '../../core/core.models';
import CustomStore from 'devextreme/data/custom_store';
import { Constants, SearchOperations } from '../../app.constants';
import { FieldsType, LookupSource } from './base.models';

@Injectable({
    providedIn: 'root'
})
export class BaseService {
    protected readonly pageSize: number = 15;

    constructor(protected requestService: RequestService) { }

    public getAll(url: string, onSuccess: Function): void {
        this.requestService.createRequest(HttpRequestType.Get, url, null, null, (response: any) => {
            onSuccess(response);
        });
    }

    public getById(url: string, id: number | string, onSuccess: Function, onFail: Function = null): void {
        this.requestService.createRequest(HttpRequestType.Get, `${url}/${id}`, null, null, (response: any) => {
            onSuccess(response);
        }, () => {
            if (onFail != null)
                onFail();
        });
    }

    public create(url: string, formData: any, onSuccess: Function, onFail: Function = null): void {
        this.requestService.createRequest(HttpRequestType.Post, url, formData, null, (response?: any) => {
            response != null ? onSuccess(response) : onSuccess();
        }, (error: any) => {
            if(onFail != null)
                onFail(error);
            else 
                this.requestService.handleError(error);
        });
    }

    public update(url: string, formData: any, onSuccess: Function, onFail: Function = null): void {
        this.requestService.createRequest(HttpRequestType.Put, url, formData, null, (response?: any) => {
            response != null ? onSuccess(response) : onSuccess();
        }, onFail != null ? (error: any) => { onFail(error) } : (error) => { this.requestService.handleError(error); });
    }

    public delete(url: string, id: number | string, onSuccess: Function = null, onFail: Function = null): void {
        this.requestService.createRequest(HttpRequestType.Delete, `${url}/${id}`, null, null, () => {
            if(onSuccess != null)
                onSuccess();
        }, (error) => {
            if(onFail != null)
                onFail(error);
            else 
                this.requestService.handleError(error);
        });
    }

    public patch(url: string, body: any = null, onSuccess: Function, onFail: Function = null): void {
        this.requestService.createRequest(HttpRequestType.Patch, url, body, null, (response?: any) => {
            response != null ? onSuccess(response) : onSuccess();
        }, onFail != null ? (error: any) => { onFail(error) } : (error) => { this.requestService.handleError(error); });
    }
    
    public changeActive(url: string, id: number | string, active: boolean, onSuccess: Function = null): void {
        this.requestService.createRequest(HttpRequestType.Patch, `${url}/${id}/${active ? 'activate' : 'deactivate'}`, null, null, () => onSuccess());
    }

    public changeActiveInForm(url: string, id: number | string, active: boolean, onSuccess: Function = null): void {
        this.requestService.createRequest(HttpRequestType.Patch, `${url}/${id}/${active ? 'deactivate' : 'activate'}`, null, null, (response) => onSuccess(response));
    }

    public getByIdAsPromise(url: string, id: number | string, onSuccess: Function, onFail: Function = null): any {
        return this.requestService.createRequestAsPromise(HttpRequestType.Get, `${url}/${id}`, null, null, onSuccess, onFail);
    }

    public getByIdAsPromiseWithFilter(url: string, id: number | string, filter: string, onSuccess: Function, onFail: Function = null): any {
        return this.requestService.createRequestAsPromise(HttpRequestType.Get, `${url}/${id}${filter ? ('?'+filter) : ''}`, null, null, onSuccess, onFail);
    }

    public getAllAsPromise(url: string, skip: number, filter: string, sort: string, onSuccess: Function, onFail: Function = null, pageSize: number = null): any {
        return this.requestService.createRequestAsPromise(HttpRequestType.Get, `${url}${url.includes('?') ? '&' : '?'}size=${pageSize || this.pageSize}&page=${skip / this.pageSize}${filter || ''}${sort || ''}`, null, null, onSuccess, onFail);
    }

    public getOptions(url: string, sortBy: string = null, modifyResult: Function = null, getActiveOptions: boolean = false): OptionsSource {
        let activeFilter = getActiveOptions ? `active${SearchOperations.EQUALITY}true` : null;
        return {
            paginate: true,
            pageSize: this.pageSize,
            store: new CustomStore({
                key: 'id',
                load: (loadOptions: any) => {
                    let filter = '';
                    if(sortBy != null)
                        filter = `${getActiveOptions ? `${SearchOperations.KEY_SEARCH}=${SearchOperations.LEFT_PARENTHESES_READABLE}${activeFilter}${SearchOperations.RIGHT_PARENTHESES_READABLE}` : null}&sortBy=${sortBy}&sortOrder=asc`;
                    if (loadOptions.searchExpr && loadOptions.searchValue)
                        filter = `&${SearchOperations.KEY_SEARCH}=${SearchOperations.LEFT_PARENTHESES_READABLE}${sortBy ? sortBy : loadOptions.searchExpr}${SearchOperations.LIKE}${loadOptions.searchValue}${getActiveOptions ? `${SearchOperations.SPLIT_SINGLE_OPERATION}${SearchOperations.SPLIT_SINGLE_OPERATION}${activeFilter}` : null}${SearchOperations.RIGHT_PARENTHESES_READABLE}&sortBy=${sortBy ? sortBy : loadOptions.searchExpr}&sortOrder=asc`;
                    if(filter == '' && getActiveOptions == true)
                        filter = `&${SearchOperations.KEY_SEARCH}=${SearchOperations.LEFT_PARENTHESES_READABLE}${activeFilter}${SearchOperations.RIGHT_PARENTHESES_READABLE}`;
                    
                    return this.getAllAsPromise(url, loadOptions.skip ? loadOptions.skip : 0, filter , null, (response: any) => {
                        let data = response;
                        if(response.content != null)
                            data = response.content;

                        if(modifyResult)
                            return modifyResult(data);

                        return data;
                    });
                },
                byKey: (id: number | string | any) => {
                    if (id != null)
                        if(typeof id === 'string' || typeof id === 'number')
                            return this.getByIdAsPromise(url, id, (response: any) => {
                                if(modifyResult)
                                    return modifyResult(response);
                                return response;
                            });
                        else if(typeof id === 'object')
                            return this.getByIdAsPromise(url, id.id, (response: any) => {
                                if(modifyResult)
                                    return modifyResult(response);
                                return response;
                            });
                }
            })
        };
    }

    /**
     * 
     * @param requestFields response fields, example ['name', 'code']
     * @param searchBy parameters that will be taken into account during the search, example ['name', 'code']
     * @param source table name, example 'product'
     * @param condition condition that will be used in WHERE statement joined by AND, example "status='DEACTIVATED',name='Andi'"
     * @param modifyResult callback function for editing response
     */
    public getLookup(requestFields: FieldsType[], searchBy: FieldsType[], source: LookupSource, condition: string = '', modifyResult: Function = null): OptionsSource {
        let url: string = `${Constants.contextPath}/lookup`;
        let baseFilter: string = `request_fields=${requestFields.join(',')}&source=${source}`;
        baseFilter += condition ? `&where=${condition}` : '';

        return {
            paginate: true,
            pageSize: this.pageSize,
            store: new CustomStore({
                key: 'id',
                load: (loadOptions: any) => {
                    let filter = baseFilter;
                    if(loadOptions.searchValue)
                        filter += `&search=${loadOptions.searchValue}&search_by=${searchBy.join(',')}`;

                    return this.getAllAsPromise(url+'/page', loadOptions.skip ? loadOptions.skip : 0, '&'+filter, null, (response: any) => {
                        let data = response;
                        if(response.content != null)
                            data = response.content.map(a => this.mapToNewPascal(a));
                        if(modifyResult)
                            return modifyResult(data);

                        return data;
                    });
                },
                byKey: (id: number | string | any) => {
                    if (id == null) return
                    return this.getByIdAsPromiseWithFilter(url, typeof id === 'object' ? id.id : id, baseFilter, (response: any) => {
                        response = this.mapToNewPascal(response);
                        if(modifyResult)
                            return modifyResult(response);
                        return response;
                    });
                }
            })
        }
    };

    private mapToNewPascal(data) {
        let oldKeys = Object.keys(data);
        let newKeys = Object.keys(data).map(v => this._snake2Pascal(v))
        let newData = {};
        for (var i = 0; i < newKeys.length; i++)
            newData[newKeys[i]] = data[oldKeys[i]]
        return newData
    }

    private _snake2Pascal(str){
        str +='';
        str = str.split('_');
        for(var i=0;i<str.length;i++)
            str[i] = (i == 0 ? str[i].slice(0,1) : str[i].slice(0,1).toUpperCase()) + str[i].slice(1,str[i].length);
        return str.join('');
    }

    public getOptionsByMultipleFieldsFilter(url: string, sortBy: string, fields: string[], modifyResult: Function = null, groupBy: string = null): OptionsSource {
        return {
            paginate: true,
            pageSize: this.pageSize,
            group: groupBy,
            store: new CustomStore({
                key: 'id',
                load: (loadOptions: any) => {
                    let filter = '';
                    if(sortBy != null)
                        filter = `&sortBy=${sortBy}&sortOrder=asc`;
                    if (loadOptions.searchExpr && loadOptions.searchValue) {
                        let fieldsFilter = '';
                        
                        if(fields != null && fields.length > 0) {
                            let counter = 0;
                            for(let field of fields) {
                                let OR = `${SearchOperations.KEY_OR}`;
                                if(counter == 0)
                                    OR = '';
                                fieldsFilter = fieldsFilter + `${OR}${SearchOperations.LEFT_PARENTHESES_READABLE}${field}${SearchOperations.LIKE}${loadOptions.searchValue}${SearchOperations.RIGHT_PARENTHESES_READABLE}`;
                                counter++;
                            }
                        }

                        filter = `&${SearchOperations.KEY_SEARCH}=${SearchOperations.LEFT_PARENTHESES_READABLE}${fieldsFilter}${SearchOperations.RIGHT_PARENTHESES_READABLE}&sortBy=${sortBy}&sortOrder=asc`;

                    }

                    return this.getAllAsPromise(url, loadOptions.skip ? loadOptions.skip : 0, filter, null, (response: any) => {
                        let data = response;
                        if(response.content != null)
                            data = response.content;

                        if(modifyResult)
                            return modifyResult(data);

                        return data;
                    });
                },
                byKey: (id: number | string | any) => {
                    if (id != null)
                        if(typeof id === 'string' || typeof id === 'number')
                            return this.getByIdAsPromise(url, id, (response: any) => {
                                if(modifyResult)
                                    return modifyResult(response);
                                return response;
                            });
                        else if(typeof id === 'object')
                            return this.getByIdAsPromise(url, id.id, (response: any) => {
                                if(modifyResult)
                                    return modifyResult(response);
                                return response;
                            });
                }
            })
        };
    }

    public getOptionsForOutsideRoute(url: string, sortBy: string = null, modifyResult: Function = null, byKeyResponse: Function = null): OptionsSource {
        return {
            paginate: true,
            pageSize: this.pageSize,
            store: new CustomStore({
                key: 'id',
                load: (loadOptions: any) => {
                    let filter = '';
                    if(sortBy != null)
                        filter = `&sortBy=${sortBy}&sortOrder=asc`;
                    if (loadOptions.searchExpr && loadOptions.searchValue)
                        filter = `&${SearchOperations.KEY_SEARCH}=${SearchOperations.LEFT_PARENTHESES_READABLE}${loadOptions.searchExpr}${SearchOperations.LIKE}${loadOptions.searchValue}${SearchOperations.RIGHT_PARENTHESES_READABLE}&sortBy=${loadOptions.searchExpr}&sortOrder=asc`;

                    return this.getAllAsPromise(url, loadOptions.skip ? loadOptions.skip : 0, filter, null, (response: any) => {
                        let data = response;
                        if(response.content != null)
                            data = response.content;

                        if(modifyResult)
                            return modifyResult(data);

                        return data;
                    });
                },
                byKey: (id: any) => {
                    if(byKeyResponse != null)
                        return byKeyResponse();
                    
                    return id;
                }
            })
        };
    }

    public getOptionsByMultipleFieldsFilterForOutsideRoute(url: string, sortBy: string, fields: string[], modifyResult: Function = null, byKeyResponse: Function = null, groupBy: string = null): OptionsSource {
        return {
            paginate: true,
            pageSize: this.pageSize,
            group: groupBy,
            store: new CustomStore({
                key: 'id',
                load: (loadOptions: any) => {
                    let filter = '';
                    if(sortBy != null)
                        filter = `&sortBy=${sortBy}&sortOrder=asc`;
                    if (loadOptions.searchExpr && loadOptions.searchValue) {
                        let fieldsFilter = '';
                        
                        if(fields != null && fields.length > 0) {
                            let counter = 0;
                            for(let field of fields) {
                                let OR = `${SearchOperations.KEY_OR}`;
                                if(counter == 0)
                                    OR = '';
                                fieldsFilter = fieldsFilter + `${OR}${SearchOperations.LEFT_PARENTHESES_READABLE}${field}${SearchOperations.LIKE}${loadOptions.searchValue}${SearchOperations.RIGHT_PARENTHESES_READABLE}`;
                                counter++;
                            }
                        }

                        filter = `&${SearchOperations.KEY_SEARCH}=${SearchOperations.LEFT_PARENTHESES_READABLE}${fieldsFilter}${SearchOperations.RIGHT_PARENTHESES_READABLE}&sortBy=${sortBy}&sortOrder=asc`;

                    }

                    return this.getAllAsPromise(url, loadOptions.skip ? loadOptions.skip : 0, filter, null, (response: any) => {
                        let data = response;
                        if(response.content != null)
                            data = response.content;

                        if(modifyResult)
                            return modifyResult(data);

                        return data;
                    });
                },
                byKey: (id: any) => {
                    if(byKeyResponse != null)
                        return byKeyResponse();
                    
                    return id;
                }
            })
        };
    }

    public getOptionsWithCustomItems(url: string, customItems: any[]): OptionsSource {
        return {
            paginate: true,
            pageSize: this.pageSize,
            store: new CustomStore({
                key: 'id',
                load: (loadOptions: any) => {
                    let filter = '';
                    if (loadOptions.searchExpr && loadOptions.searchValue)
                        filter = `&${SearchOperations.KEY_SEARCH}=${SearchOperations.LEFT_PARENTHESES_READABLE}${loadOptions.searchExpr}${SearchOperations.LIKE}${loadOptions.searchValue}${SearchOperations.RIGHT_PARENTHESES_READABLE}&sortBy=${loadOptions.searchExpr}&sortOrder=asc`;

                    return this.getAllAsPromise(url, loadOptions.skip ? loadOptions.skip : 0, filter, null, (response: any) => {
                        for(let item of customItems)
                            response.content.push(item);
                        return response.content;
                    });
                },
                byKey: (id: number | string | any) => {
                    if (id != null)
                        return id;
                }
            })
        };
    }

    public getEnumsByType(list: any[], byKeyResponse: Function = null): OptionsSource {
        return {
            paginate: true,
            pageSize: this.pageSize,
            store: new CustomStore({
                key: 'value',
                load: (loadOptions: any) => {
                    return list;
                },
                byKey: (id: any) => {
                    if(byKeyResponse != null)
                        return byKeyResponse();
                }
            })
        };
    }

    public getList(url: string, filter: string, sort: string, onSuccess: Function, onFail: Function = null) {
        this.requestService.createRequest(HttpRequestType.Get, `${url}/list${filter != null || sort != null ? '?' : ''}${filter || ''}${sort || ''}`, null, null, (response) => {
            onSuccess(response);
        }, (error) => {
            if(onFail != null)
                onFail(error);
            else 
                this.requestService.handleError(error);
        })
    }


    public getListForReport(url: string, reportCode: string, filter: string, sort: string, onSuccess: Function, onFail: Function = null) {
        if(reportCode.startsWith('CTLGS')) {
            url = `${url}/for-report`;
        } else {
            url = `${url}/list`;
        }
        this.requestService.createRequest(HttpRequestType.Get, `${url}${filter != null || sort != null ? '?' : ''}${filter || ''}${sort || ''}`, null, null, (response) => {
            onSuccess(response);
        }, (error) => {
            if(onFail != null)
                onFail(error);
            else 
                this.requestService.handleError(error);
        })
    }
}
