<mat-toolbar class="toolbar">
    <button mat-icon-button class="outline-none" (click)="toggleMenu()">
      <mat-icon class="menu-item-icon">menu</mat-icon>
    </button>
    <span class="spacer"></span>
    <img *ngIf="logo" [src]="logo" class="img-fluid height-30p d-block m-auto clickable outline-none" (click)="toggleMenu()">
    <span class="spacer"></span>
    <mat-icon [matTooltip]="'SHARED.NOTIFICATIONS' | translate" matTooltipPosition="above" matTooltipHideDelay="200" [matBadge]="notificationsService.unreadNotificationsNumber" matBadgeColor="warn" color="primary" [routerLink]="['/dashboard/notifications/'+defaultNotification]" *ngIf="notificationsService.unreadNotificationsNumber > 0" class="clickable outline-none animate__animated animate__wobble animate__infinite infinite animate__delay-1s animate__slow">email</mat-icon>
    <mat-icon [matTooltip]="'SHARED.NOTIFICATIONS' | translate" matTooltipPosition="above" matTooltipHideDelay="200" color="primary" [routerLink]="['/dashboard/notifications/'+defaultNotification]" *ngIf="notificationsService.unreadNotificationsNumber == 0" class="clickable outline-none">email</mat-icon>
    <!-- <a [routerLink]="['/dashboard/notifications/'+defaultNotification]" *ngIf="notificationsService.unreadNotificationsNumber > 0" class="animate__animated animate__wobble animate__infinite infinite animate__delay-1s animate__slow">
      <mat-icon>email</mat-icon>
      <span [matBadge]="notificationsService.unreadNotificationsNumber">{{notificationsService.unreadNotificationsNumber}}</span>
    </a> -->
    <button mat-button [matMenuTriggerFor]="afterMenu">
        <mat-icon class="menu-item-icon">person</mat-icon>
        <span class="username">{{ authService.username }}</span>
        <mat-icon class="menu-item-icon">expand_more</mat-icon>
    </button>
    <mat-menu #afterMenu="matMenu" xPosition="after">
      <button mat-menu-item [routerLink]="['/profile']">
        <mat-icon>person</mat-icon>
        {{ 'MENU.RIGHT.PROFILE-SHORT' | translate }}
      </button>
      <!-- <button *ngIf="authService.hasAnyAuthority('R_NTF')" mat-menu-item [routerLink]="['/dashboard/notifications/'+defaultNotification]">
        <mat-icon>message</mat-icon>
        {{ 'SHARED.NOTIFICATIONS' | translate }}
      </button> -->
      <button mat-menu-item [routerLink]="['/logout']">
        <mat-icon>logout</mat-icon>
        {{ 'MENU.RIGHT.LOGOUT' | translate }}
      </button>
    </mat-menu>
  </mat-toolbar>
  
  <mat-drawer-container class="drawer-container" autosize>
    <mat-drawer #drawer class="border-0 drawer-sidenav" [mode]="drawerMode" opened>
      <mat-tree [dataSource]="dataSource" [treeControl]="treeControl" class="example-tree">
        <!-- This is the tree node template for leaf nodes -->
        <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle>
          <li class="mat-tree-node">
            <!-- use a disabled button to provide padding for tree leaf -->
            <button class="menu-item outline-none w-100 text-left" [routerLink]="[node.routerLink]" routerLinkActive="router-link-active" mat-button>
              <mat-icon class="menu-item-icon">{{ node.icon }}</mat-icon>
              <span class="menu-item-text">{{ node.key | translate}}</span>
            </button>
          </li>
        </mat-tree-node>
        <!-- This is the tree node template for expandable nodes -->
        <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild">
          <li>
            <div class="mat-tree-node">
              <button class="menu-item outline-none w-100 text-left" mat-button matTreeNodeToggle
                [attr.aria-label]="'Toggle ' + node.key">
                <mat-icon class="mat-icon-rtl-mirror menu-item-icon">
                  {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
                </mat-icon>
                <span class="outline-none">
                  <!-- <mat-icon class="menu-item-icon">{{ node.icon }}</mat-icon> -->
                  <span class="menu-item-text">{{ node.key | translate}}</span>
                </span>
              </button>
            </div>
            <ul [class.example-tree-invisible]="!treeControl.isExpanded(node)" [ngClass]="{'bg-tree-dark': treeControl.isExpanded(node)}">
              <ng-container matTreeNodeOutlet></ng-container>
            </ul>
          </li>
        </mat-nested-tree-node>
      </mat-tree>
  
    </mat-drawer>
  
    <mat-drawer-content class="main-content p-2">
      <div>
          <router-outlet></router-outlet>
      </div>
    </mat-drawer-content>
  
  </mat-drawer-container>