import { Component, OnInit } from '@angular/core';
import { LoadingService } from './core/loading.service';
import * as localization from "devextreme/localization";
import config from "devextreme/core/config";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{
  title = 'b2b-webshop';
  showApp: boolean = false;

  constructor(public loadingService: LoadingService) {
    (localization as any).disableIntl();
    config({
        thousandsSeparator: '.',
        decimalSeparator: ','
        // ...
    })
  }

  ngOnInit() {
    setTimeout(() => {
      this.showApp = true;
    }, 500);
  }
}
