import { NgModule, forwardRef, Provider } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { InsideGuard, OutsideGuard, PendingChangesGuard } from './guards';


import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpErrorInterceptor } from './http-error.interceptor';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InsideLayoutModule } from './inside-layout/inside-layout.module';
import { SharedModule } from './shared/shared.module';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { ToastrModule } from 'ngx-toastr';
import { OutsideLayoutModule } from './outside-layout/outside-layout.module';
import { OutsideLayoutComponent } from './outside-layout/outside-layout/outside-layout.component';
import { EmailChangeConfirmationComponent } from './outside-layout/email-change-confirm/email-change-confirm.component';

export const API_INTERCEPTOR_PROVIDER: Provider = {
    provide: HTTP_INTERCEPTORS,
    useExisting: forwardRef(() => HttpErrorInterceptor),
    multi: true
};


export const AppRoutes: Routes = [
    {
        path: 'change-email/:token/confirm',
        component: EmailChangeConfirmationComponent
    },
    {
        path: '',
        canActivate: [OutsideGuard],
        loadChildren: () => import('./outside-layout/outside-layout.module').then(m => m.OutsideLayoutModule)
    },
    {
        path: '',
        canActivate: [InsideGuard],
        loadChildren: () => import('./inside-layout/inside-layout.module').then(m => m.InsideLayoutModule)
    },
    {
        path: '**',
        component: OutsideLayoutComponent
    }
];

export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
    declarations: [
        AppComponent,
        EmailChangeConfirmationComponent
    ],
    imports: [
        BrowserModule,
        RouterModule.forRoot(
            AppRoutes,
            {
                onSameUrlNavigation: 'reload',
                preloadingStrategy: PreloadAllModules
            }
        ),
        BrowserAnimationsModule,
        BrowserAnimationsModule,
        FormsModule,
        ReactiveFormsModule,
        OutsideLayoutModule,
        InsideLayoutModule,
        HttpClientModule,
        SharedModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: (createTranslateLoader),
                deps: [HttpClient]
            },
            isolate: false
        }),
        ToastrModule.forRoot({
            maxOpened: 5,
            positionClass: 'toast-bottom-right',
            preventDuplicates: true
        })
    ],
    providers: [
        InsideGuard,
        OutsideGuard,
        PendingChangesGuard,
        HttpErrorInterceptor,
        API_INTERCEPTOR_PROVIDER
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }