import { NestedTreeControl } from '@angular/cdk/tree';
import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { AuthService } from 'src/app/core/auth.service';
import { SettingsService } from 'src/app/core/settings.service';
import { MenuItem } from 'src/app/shared/base/base.models';
import { NotificationType } from 'src/app/shared/notifications/notifications.models';
import { NotificationsService } from 'src/app/shared/notifications/notifications.service';
import { CompanyConfigurationKey } from '../routes/profile/profile.models';
import { ProfileService } from '../routes/profile/profile.service';
import { Menu } from './menu';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit {
  @ViewChild('drawer') drawer: MatDrawer;
  logo = window.location.hostname;
  // menuSubscription: Subscription;
  menuItems: MenuItem[] = Menu;
  treeControl = new NestedTreeControl<MenuItem>(item => item.children);
  dataSource = new MatTreeNestedDataSource<MenuItem>();
  defaultNotification = NotificationType[NotificationType.SYSTEM].toLowerCase();
  drawerMode: 'side' | 'over' = window.innerWidth > 1199 ? 'side' : 'over';

  constructor(private settingsService: SettingsService, public authService: AuthService, public notificationsService: NotificationsService, private profileService: ProfileService) {
    this.generateMenuItems();
  }

  hasChild = (_: number, node: MenuItem) => !!node.children && node.children.length > 0;

  ngOnInit(): void {
    this.profileService.getLogo().then(() => this.logo = this.profileService.logo);
    if (this.authService.hasAuthority('R_NTF'))
      this.notificationsService.checkUnreadNotifications();
  }

  ngAfterContentInit() {
    this.setDrawerSettings();
  }

  toggleMenu() {
    this.drawer.toggle();
    this.settingsService.setMenuOpenedValue(this.drawer != null ? this.drawer.opened : false);
  }

  ngOnDestroy() {
    // this.menuSubscription.unsubscribe();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.setDrawerSettings();
  }

  setDrawerSettings() {
    if (this.drawer != null) {
      this.drawerMode = window.innerWidth > 1199 ? 'side' : 'over';
      if (window.innerWidth < 1200) {
        this.drawer.close();
      } else {
        this.drawer.open();
      }
    }
  }

  generateMenuItems() {
    let items = [];
    for(let item of this.menuItems) {
      if(item.authorities == null || item.authorities.length == 0 || this.authService.hasAnyAuthority(...item.authorities))
        items.push(item);
    }

    this.dataSource.data = items;
  }
}
