import { Injectable } from '@angular/core';
import { Constants, SearchOperations } from 'src/app/app.constants';
import { AuthService } from 'src/app/core/auth.service';
import { HttpRequestType, RequestService } from 'src/app/core/request.service';
import { AppClientType } from 'src/app/shared/base/base.models';
import { BaseService } from 'src/app/shared/base/base.service';
import { Partner, CompanyConfiguration, CompanyConfigurationKey } from './profile.models';

@Injectable({
  providedIn: 'root'
})
export class ProfileService extends BaseService {
  logo: string;

  constructor(protected requestService: RequestService, private authService: AuthService) {
    super(requestService)
  }

  public getLegalEntity(onSuccess: Function) {
    this.requestService.createRequest(HttpRequestType.Get, `${Constants.contextPath}/partners/${this.authService.partnerId}`, null, null, (response) => {
      onSuccess(response);
    });
  }

  public updateLegalEntity(partner: Partner, onSuccess: Function) {
    let data: any = {...partner};
    data.country = partner.country?.id;
    this.update(`${Constants.contextPath}/partners`, data, (response) => {
      onSuccess(response);
    })
  }

  public getCountriesOptions() {
    return this.getOptions(`${Constants.contextPath}/countries`);
  }

  public changePassword(body: any, onSuccess: Function, onFail: Function) {
    this.requestService.createRequest(HttpRequestType.Patch, `${Constants.contextPath}/users/changepassword`, body, null, (response: any) => {
      onSuccess(response);
    }, (error: any) => {
      onFail(error);
    });
  }

  public changeEmail(email: string, id: string, onSuccess: Function, onFail: Function) {
    let url = `${Constants.contextPath}/users/change-email/${email}/${AppClientType[AppClientType.B2B_SHOP]}`;
    this.requestService.createRequest(HttpRequestType.Patch, url, null, null, (response: any) => {
      onSuccess(response);
    }, (error: any) => {
      onFail(error);
    });
  }

  public resetPassword(userId: string, onSuccess: Function) {
    this.requestService.createRequest(HttpRequestType.Patch, `${Constants.contextPath}/users/${userId}/reset-pass`, null, null, (response: any) => {
      onSuccess(response);
    });
  }

  public getCountryConfiguration(countryId: string, onSucces: Function) {
    this.getAll(`${Constants.contextPath}/country-configurations/list?${SearchOperations.KEY_SEARCH}=${SearchOperations.LEFT_PARENTHESES_READABLE}country${SearchOperations.SPLIT_JOIN_OPERATOR}id${SearchOperations.EQUALITY}${countryId}${SearchOperations.RIGHT_PARENTHESES_READABLE}`, (res) => {
      onSucces(res);
    })
  }

  public getByConfigurationKey(key: string): Promise<CompanyConfiguration> {
    return new Promise(resolve => {
      this.getAll(`${Constants.contextPath}/companies/get-by-key/${key}`, res => resolve(res));
    })
  }

  public async getLogo() {
    this.logo = (await this.getByConfigurationKey(CompanyConfigurationKey[CompanyConfigurationKey.ICON_LOGO_URL])).value;
  }
}
