import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DxDataGridComponent } from 'devextreme-angular';
import CustomStore from 'devextreme/data/custom_store';
import { LoadOptions } from 'devextreme/data/load_options';
import { Constants } from 'src/app/app.constants';
import { AlertService } from 'src/app/core/alert.service';
import { AuthService } from 'src/app/core/auth.service';
import { DialogService } from 'src/app/core/dialog.service';
import { LoadingService } from 'src/app/core/loading.service';
import { HttpRequestType, RequestService } from 'src/app/core/request.service';
import { UtilityService } from 'src/app/core/utility.service';
import { PointOfSale, SearchMap, ShopParams } from 'src/app/inside-layout/routes/offered-products/offered-products.models';
import { OfferedProductsService } from 'src/app/inside-layout/routes/offered-products/offered-products.service';
import { BaseListComponent } from '../base/base-list.component';
import { RouteAuthorities } from '../base/base.models';
import { BaseService } from '../base/base.service';
@Component({
  selector: 'app-products-dropdown',
  templateUrl: './products-dropdown.component.html',
  styleUrls: ['./products-dropdown.component.scss']
})
export class ProductsDropdownComponent extends BaseListComponent implements OnInit {
  @ViewChild('dropDownBox') dropDownBox;
  @ViewChild('grid') grid: DxDataGridComponent;
  @Input('pointOfSale') pointOfSale: PointOfSale;
  @Input('height') height: number = 600;
  @Input('readOnly') readOnly: boolean = false;
  @Output() onSelectionChangedEvent = new EventEmitter();

  // private skip: number;
  protected readonly pageSize: number = 36;

  constructor(
    public authService: AuthService,
    public loadingService: LoadingService,
    public baseService: BaseService,
    public dialogService: DialogService,
    public alertService: AlertService,
    public utilityService: UtilityService,
    public requestService: RequestService,
    public translateService: TranslateService,
    private offeredProductsService: OfferedProductsService
  ) {
    super(authService, loadingService, baseService, dialogService, alertService, utilityService, requestService, translateService);
    this.authorities = new RouteAuthorities(authService, alertService, 'CD_CD', 'R_CD', 'U_CD', 'CD_CD');
    this.creatable = this.authorities.create;
    this.editable = this.authorities.update;
    this.deletable = this.authorities.delete;
    this.activatable = this.authorities.update;
  }

  ngOnInit() {
    this.url = `${Constants.contextPath}/offered-products/b2b`;
    this.getGridData();
    setTimeout(() => {
      this.dropDownBox.instance.open();
    }, 500);
  }

  itemClick(e) {
  }

  onSelectionChanged(e) {
    this.onSelectionChangedEvent.emit(e?.selectedRowsData[0]);
    this.dropDownBox.instance.close();
  }

  userGridFilterCell: any

  onCellPrepared(e) {
    if (e.rowType === "filter" && e.columnIndex === 0) {
      let cellElement = e.cellElement;
      this.userGridFilterCell = cellElement.querySelectorAll(".dx-texteditor-input");
    }
  }

  protected getGridData(): void {
    let options: ShopParams = new ShopParams();
    options.pageSize = this.pageSize;
    options.searchMap = new SearchMap();

    this.dataSource = new CustomStore({
      key: 'id',
      load: (loadOptions: LoadOptions) => {
        options.page = loadOptions.skip ? loadOptions.skip : 0;
        if (loadOptions.filter != null)
          options.searchMap = this.getSearchMap(loadOptions.filter);
        else
          options.searchMap = new SearchMap();

        options.searchMap.pointOfSaleName = this.pointOfSale.name;
        return this.requestService.createRequestAsPromise(HttpRequestType.Post, this.url, options, null, (response) => {

          setTimeout(() => {
            if (loadOptions.skip == 0 && this.userGridFilterCell !== undefined && this.userGridFilterCell.length > 0)
              this.userGridFilterCell[0].focus();
          }, 100);

          return {
            data: response.content,
            totalCount: response.totalElements
          };
        });
      },
      byKey: () => {
        return null;
      }
    });
  }

  protected getSearchMap(filter: any): SearchMap {
    let searchMap = new SearchMap();
    let filterArray = filter

    if (filter.indexOf('and') == -1 && filter.indexOf('or') == -1)
      filterArray = [[filter[0], filter[1], filter[2]]];
    else if (filter.indexOf('or') != -1)
      filterArray = [[filter[0][0], '<>', filter.filterValue]];

    for (let filterItem of filterArray)
      if (Array.isArray(filterItem))
        if (filterItem.indexOf('and') == -1 && filterItem.indexOf('or') == -1) {
          if (filterItem[0] == 'product.code' || filterItem[0] == 'product.name')
            searchMap.name = filterItem[2];
        }

    return searchMap
  }
}