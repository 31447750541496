
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Route, RouterModule } from '@angular/router';
import { OutsideGuard } from '../guards';
import { MaterialModule } from '../shared/material.module';
import { SharedModule } from '../shared/shared.module';
import { OutsideLayoutComponent } from './outside-layout/outside-layout.component';

export const outsideRoutes: Route[] = [
    {
        path: '',
        component: OutsideLayoutComponent,
        canActivate:[OutsideGuard],
        children: [
            {
                path: '', redirectTo: 'login', pathMatch: 'full'
            },
            {
                path: 'login',
                component: OutsideLayoutComponent
            },
            {
                path: 'register',
                component: OutsideLayoutComponent
            },
            {
                path: 'forgot-password',
                component: OutsideLayoutComponent
            }
        ]
    }
];

@NgModule({
    declarations: [OutsideLayoutComponent],
    imports: [
        FormsModule,
        ReactiveFormsModule,
        SharedModule,
        MaterialModule,
        RouterModule.forChild(outsideRoutes)
    ],
    exports: [

    ]
})
export class OutsideLayoutModule { }
