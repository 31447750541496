import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/core/auth.service';
import { AlertService } from 'src/app/core/alert.service';
import { NavigationEnd, Router } from '@angular/router';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import { Constants } from 'src/app/app.constants';
import { HttpRequestType, RequestService } from 'src/app/core/request.service';
import { environment } from 'src/environments/environment';
import { HttpHeaders } from '@angular/common/http';


@Component({
  selector: 'app-outside-layout',
  templateUrl: './outside-layout.component.html',
  styleUrls: ['./outside-layout.component.scss']
})
export class OutsideLayoutComponent implements OnInit {
  passVisibility: boolean = true;
  passVisibility2: boolean = true;
  passMatch: boolean = false;
  showForm: boolean = false;
  buttonTextTranslationKey: string;
  headingTranslationKey: string;
  paragraphTranslationKey: string;
  FormType = FormType;
  formType: FormType;

  public formData = this.formBuilder.group({
    'name': ['', [Validators.required, Validators.minLength(1), Validators.maxLength(60)]],
    'suggestionName': ['', [Validators.required, Validators.minLength(1), Validators.maxLength(255)]],
    'idNumber': ['', [Validators.minLength(1), Validators.maxLength(50)]],
    'telephone': ['', [Validators.minLength(1), Validators.maxLength(20)]],
    'email': ['', [Validators.required, Validators.minLength(3), Validators.maxLength(100), Validators.email]],
    'password': ['', [Validators.required, Validators.minLength(6), Validators.maxLength(60)]],
    'confirmPassword': ''
  }, { validator: this.checkPasswords });

  public logo = window.location.hostname;

  constructor(
    private formBuilder: FormBuilder,
    private requestService: RequestService,
    private authService: AuthService,
    private alertService: AlertService,
    public router: Router
  ) {
    router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        this.determineFormType();
      }
    });
  }

  ngOnInit(): void {
    this.determineFormType();
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.showForm = true;
    }, 500);
  }

  determineFormType() {
    let url = this.router.url.replace('/', '').replace('-', '').toUpperCase();

    switch (url) {
      case FormType.REGISTER:
        this.formType = FormType.REGISTER;
        this.headingTranslationKey = 'REGISTRATIONS.HEADING';
        this.buttonTextTranslationKey = 'REGISTRATIONS.SUBMIT';
        this.paragraphTranslationKey = 'REGISTRATIONS.FILL_FORM';
        break;
      case FormType.LOGIN:
        this.formType = FormType.LOGIN;
        this.headingTranslationKey = 'REGISTRATIONS.LOGIN';
        this.buttonTextTranslationKey = 'REGISTRATIONS.LOGIN';
        this.paragraphTranslationKey = null;
        break;
      case FormType.FORGOTPASSWORD:
        this.formType = FormType.FORGOTPASSWORD;
        this.headingTranslationKey = 'SHARED.ENTER-HERE-EMAIL';
        this.buttonTextTranslationKey = 'MODAL.CONFIRM';
        this.paragraphTranslationKey = 'REGISTRATIONS.FORGOT_PW';
        break;
      case FormType.CONFIRMREGISTRATION: // only for confirmingRegistration, nothing to input
        this.formType = FormType.CONFIRMREGISTRATION;
        this.headingTranslationKey = null;
        this.buttonTextTranslationKey = null;
        this.paragraphTranslationKey = null;
        break;
      case FormType.NEWPASSWORD: // only for confirmingRegistration, nothing to input
        this.formType = FormType.NEWPASSWORD;
        this.headingTranslationKey = 'REGISTRATIONS.ENTER_HERE_NEW_PASSWORD';
        this.buttonTextTranslationKey = 'MODAL.CONFIRM';
        this.paragraphTranslationKey = null;
        break;
    }
  }

  submitForm() {
    switch (this.formType) {
      case FormType.FORGOTPASSWORD:
        this.forgotPassword();
        break;
      case FormType.LOGIN:
        this.login()
        break;
      case FormType.REGISTER:
        this.register();
        break;
      case FormType.NEWPASSWORD:
        this.setNewPassword();
        break;
    }
  }

  register() {
    let data = this.formData.value;
    data.email = (data.email.toLowerCase()).trim();
    data.companyId = this.authService.determineCompanyID().companyId;
    this.requestService.createRequest(HttpRequestType.Post, `${Constants.contextPath}/registration-requests`, data, new HttpHeaders(), (response) => {
      this.alertService.success('REGISTRATIONS.SUCCESS');
      this.formData.reset();
      this.router.navigate(['/login']);
    }, (error) => {
      this.alertService.error(error, true);
    });
  }

  public login(): void {
    let data = this.formData.value;
    if(data.email != null || data.password != null) {
      this.authService.login(data.email.toLowerCase().trim(), data.password).subscribe(_ => {
        // TODO: otkomentarisati dole kada se doda partner
        this.router.navigate(['dashboard']);
        this.formData.reset();
        if (this.authService.hasAnyRole('PARTNER_USER_ADMIN', 'PARTNER_USER')) {
          this.router.navigate(['dashboard']);
          this.formData.reset();
        } else {
          this.authService.removeAuth();
          this.alertService.error('ERRORS.WRONG_USER_TYPE');
        }
      }, (error) => {
        if (error.error.error === 'unauthorized')
          this.alertService.error('ERRORS.LOGIN_FAILED');
        else if (error.error.error_description === 'User account is locked')
          this.forgotPassword(true);
        else
          this.alertService.error(error, true);
      });
    } else {
      this.alertService.error('ERRORS.EMAIL_PASSWORD_EMPTY')
    }
    
  }

  private forgotPassword(lockedAccount: boolean = false) {
    let data = this.formData.value;

    this.requestService.createRequest(HttpRequestType.Patch, `${Constants.contextPath}/users/forgot-password/${data.email.toLowerCase().trim()}/B2B_SHOP`, null, null, (res) => {
      this.formData.reset();
    }, (error) => {
      this.alertService.error('ERRORS.GENERAL', false, 5000);
    });
  }

  confirmRegistration() {
    // this.token = this.router.snapshot.paramMap.get('registrationConfirmationToken');
    // if (this.token)
    //   this.registerService.confirmRegistration(this.token, (response) => {
    //     this.succesEvent.emit({ type: this.RouteParams.registrationConfirmationUrl, response: response });
    //   }, (error) => {
    //     this.errorEvent.emit({ type: this.RouteParams.registrationConfirmationUrl, error });
    //   })
    // else
    //   this.errorEvent.emit({ type: this.RouteParams.registrationConfirmationUrl, error: 'token-error' });
  }


  private setNewPassword() {
    // let forgotPasswordToken = this.router.snapshot.paramMap.get('forgotPasswordToken');
    // let data = this.formData.value;

    // this.registerService.resetPw(forgotPasswordToken, data.password, () => {
    //   this.alertService.success('PASSWORD.SUCCESS_RESET_PASSWORD_LOGOUT');
    //   this.route.navigate(['/'+this.RouteParams.login]);
    // });
  }


  checkIsButtonDisabled(form): boolean {
    switch (this.formType) {
      case FormType.FORGOTPASSWORD:
        return form.value.password === form.value.confirmPassword ? false : true;
      case FormType.LOGIN:
        return (form.value.email.length > 2 && form.value.password.length > 5) ? false : true;
      case FormType.REGISTER:
        return form.status == "VALID" ? false : true;
      case FormType.NEWPASSWORD:
        return form.value.password == form.value.confirmPassword ? (form.value.password.length > 7 ? false : true) : true;
    }
  }


  checkPasswords(group: FormGroup) {
    let pass = group.get('password').value;
    let confirmPass = group.get('confirmPassword').value;
    return pass === confirmPass ? null : { notSame: true }
  }
}


enum FormType {
  REGISTER = 'REGISTER', LOGIN = 'LOGIN', FORGOTPASSWORD = 'FORGOTPASSWORD', NEWPASSWORD = 'NEWPASSWORD', CONFIRMREGISTRATION = 'CONFIRMREGISTRATION'
}