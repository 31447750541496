import { Component, OnInit } from '@angular/core';

import { TermsPolicyCookiesService } from './terms-policy-cookies.service';

@Component({
    selector: 'sm-terms-policy-cookies',
    templateUrl: './terms-policy-cookies.component.html',
    styleUrls: ['./terms-policy-cookies.component.scss']
})
export class TermsPolicyCookiesComponent implements OnInit {
    private readonly storageKey = 'cookies_accepted';

    constructor(public tpcService: TermsPolicyCookiesService) { }

    ngOnInit() {
        if (!!localStorage.getItem(this.storageKey))
            this.tpcService.showCookiesConsent = false;
    }

    public accept(): void {
        localStorage.setItem(this.storageKey, 'true');
        this.tpcService.showCookiesConsent = false;
    }
}
