import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanDeactivate, Router } from '@angular/router';
import { Observable } from 'rxjs';

import { AuthService } from '../app/core/auth.service';

@Injectable({
    providedIn: 'root'
})
export class InsideGuard implements CanActivate {
    constructor(private authService: AuthService, private router: Router) { }    
    
    canActivate() {
        if (!this.authService.isTokenSet) {
            this.router.navigate(['login']);
            return false;
        }

        return true;
    }
}

@Injectable({
    providedIn: 'root'
})
export class OutsideGuard implements CanActivate {
    constructor(private authService: AuthService, private router: Router) { }

    canActivate(route: ActivatedRouteSnapshot) {
        if (this.authService.isTokenSet) {
            this.router.navigate(['dashboard']);
            return false;
        }

        if (route.data.performRedirection != null && !route.data.performRedirection)
            this.authService.removeAuth();
        
        if (this.authService.isTokenSet) {
            this.authService.performRedirection();

            return false;
        }

        return true;
    }
}

@Injectable({
    providedIn: 'root'
})
export class RoleGuard implements CanActivate {
    constructor(private authService: AuthService) { }

    canActivate(route: ActivatedRouteSnapshot) {
        if (!route.data.authorities || this.authService.hasAnyAuthority(...route.data.authorities))
            return true;

        this.authService.performRedirection();

        return false;
    }
}

@Injectable({
    providedIn: 'root'
})
export class PendingChangesGuard implements CanDeactivate<ComponentCanDeactivate> {
    canDeactivate(component: any): boolean | Observable<boolean> {
        return component.canDeactivate() ? true : component.pendingChangesDialog;
    }
}

interface ComponentCanDeactivate {
    canDeactivate: () => boolean | Observable<boolean>;
}
