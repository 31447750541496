import { NgModule } from '@angular/core';
import { LayoutComponent } from './layout/layout.component';
import { RouterModule } from '@angular/router';
import { DxModule } from '../shared/dx.module';
import { MaterialModule } from '../shared/material.module';
import { SharedModule } from '../shared/shared.module';
import { routes } from './routes/routes';
import { LogoutComponent } from './routes/logout/logout.component';

@NgModule({
  declarations: [LayoutComponent, LogoutComponent],
  imports: [
    SharedModule,
    MaterialModule,
    DxModule,
    RouterModule.forChild(routes)
  ],
  exports: [
    LayoutComponent, LogoutComponent
  ]
})
export class InsideLayoutModule { }
