import { BaseModel } from "src/app/shared/base/base.models";

export class Partner extends BaseModel {
    name?: string
    type?: BusinessPartnerType;
    erpCode?: string;
    address?: string;
    zipCode?: string;
    place?: string;
    country?: Country;
    idNumber?: string;
    vatPayer?: boolean;
    vatNumber?: string;
    contactPerson?: string;
    telephone?: string;
    fax?: string;
    email?: string;
    website?: string;
    geoX?: number;
    geoY?: number;
    status?: BusinessPartnerStatus;

}

export enum BusinessPartnerType {
    B2B_CUSTOMER
}

export enum BusinessPartnerStatus {
    ACTIVE, INACTIVE, SUSPENDED
}


export class Country extends BaseModel {
    name?: string;
    officialName?: string;
    alpha2Code?: string;
    alpha3Code?: string;
    numericCode?: string;
    topLevelDomain?: string;
    currency?: Currency;
}

export class Currency extends BaseModel {
    currencyName?: string;
    isoCurrencyCode?: string;
    numericCurrencyCode?: string;
    localCurrencyCode?: string;
}

export class CompanyConfiguration {
    key: string | CompanyConfigurationKey;
    value: string;
}

export enum CompanyConfigurationKey {
    // PASS
    PASS_VALIDATOR, PASS_VALIDITY_PERIOD, PASS_LENGTH_ADMIN, PASS_LENGTH_USER, PASS_CONFIRMATION_LINK, PASS_PENDING_PASSWORD_PERIOD,
    
    
    // SMTP
    SMTP_HOST, SMTP_PORT, SMTP_USERNAME, SMTP_PASSWORD, SMTP_SSL, SMTP_FROM_NAME, SMTP_FROM_EMAIL,

    // HTTP
    HTTP_PROTOCOL, HTTP_BASE_FRONT_URL, HTTP_BASE_FRONT_URL_B2B,
    //
    PUBLIC_USER_ID,
    //
    STOCK_QUANTITY_UPDATE, PRODUCT_INSERT,

    //SHOP DISPLAY DELIVERY TIME
    DELIVERY_SHOW_TODAY, DELIVERY_FIRST_TIMESLOT_HOURS_STEP, DELIVERY_SHOW_DAYS,

    //GENERATING DISTRIBUTION CALENDARS CONFIGURATION
    GENERATING_CALENDARS_HOURS_STEP,

    //OPA
    OPA_URL, REQUEST_QR_PAYMENT_DURATION,

    //UPC - PAYMENT
    UPC_MERCHANT_ID, UPC_TERMINAL_ID, UPC_CURRENCY_NUMBER, UPC_LOCALE_CODE, UPC_SUCCESS_URL, UPC_FAIL_URL, UPC_PREAUTHORIZATION,

    // IMAGES
    ICON_LOGO_URL
}