<div class="wrapper d-flex">
	<div class="toast-container">
		<app-toast></app-toast>
	</div>
    <div class="form-container normalAnim" [ngClass]="{'show': showForm}">
		<div class="content">
			<h2 class="heading shortAnim">
				{{ headingTranslationKey | translate }}
			</h2>
			<p [ngClass]="{'hide': !paragraphTranslationKey }" class="info shortAnim">{{ paragraphTranslationKey | translate }}</p>
			<form [formGroup]="formData" (keydown.enter)="$event.preventDefault(); submitForm()" class="row" autocomplete="on">
					<mat-form-field [ngClass]="{'hide': formType != [FormType.REGISTER]}" class="col-12 formFieldShrink shortAnim max-width">
						<mat-label class="text-center">{{ 'SHARED.FIRST_NAME_LAST_NAME' | translate }}</mat-label>
						<input [tabindex]="1" matInput [formControl]="formData.controls['name']">
						<mat-icon class="mr-2 opacity50 fs-14" matPrefix>person</mat-icon>
					</mat-form-field>
					<mat-form-field [ngClass]="{'hide': formType == [FormType.NEWPASSWORD] || formType == [FormType.CONFIRMREGISTRATION] }" class="col-12 formFieldShrink shortAnim max-width">
						<mat-label class="text-center">{{ 'SHARED.EMAIL' | translate }}</mat-label>
						<input [tabindex]="formType == [FormType.LOGIN] ? 1 : 2" matInput autofocus type="email" [formControl]="formData.controls['email']" autocomplete="on">
						<mat-icon class="mr-2 opacity50 fs-14" matPrefix>alternate_email</mat-icon>
					</mat-form-field>
					<mat-form-field  [ngClass]="{'hide': formType != [FormType.REGISTER]}" class="col-12 formFieldShrink shortAnim max-width">
						<mat-label class="text-center">{{ 'REGISTRATIONS.SUGGESTION_NAME' | translate }}</mat-label>
						<input matInput [formControl]="formData.controls['suggestionName']">
						<mat-icon class="mr-2 opacity50 fs-14" matPrefix>domain</mat-icon>
					</mat-form-field>
					<mat-form-field [ngClass]="{'hide': formType != [FormType.REGISTER]}" class="col-12 formFieldShrink shortAnim max-width">
						<mat-label class="text-center">{{ 'REGISTRATIONS.ID_NUMBER' | translate }}</mat-label>
						<input matInput [formControl]="formData.controls['idNumber']">
						<mat-icon class="mr-2 opacity50 fs-14" matPrefix>corporate_fare</mat-icon>
					</mat-form-field>
					<mat-form-field [ngClass]="{'hide': formType != [FormType.REGISTER]}" class="col-12 formFieldShrink shortAnim max-width">
						<mat-label class="text-center">{{ 'REGISTRATIONS.TELEPHONE' | translate }}</mat-label>
						<input matInput type="tel" [formControl]="formData.controls['telephone']">
						<mat-icon class="mr-2 opacity50 fs-14" matPrefix>call</mat-icon>
					</mat-form-field>
					<mat-form-field  [ngClass]="{'hide': formType == [FormType.FORGOTPASSWORD] || formType == [FormType.CONFIRMREGISTRATION] || formType == [FormType.REGISTER] }" class="col-12 formFieldShrink shortAnim max-width">
						<mat-label class="text-center">{{ 'SHARED.PASSWORD' | translate }}</mat-label>
						<input [tabindex]="formType == [FormType.LOGIN] ? 2 : null" matInput [formControl]="formData.controls['password']" [type]="passVisibility2 ? 'password' : 'text'" autocomplete="current-password">
						<button class="outline-none" mat-icon-button matSuffix (click)="passVisibility2 = !passVisibility2" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="passVisibility2">
							<mat-icon>{{passVisibility2 ? 'visibility_off' : 'visibility'}}</mat-icon>
						</button>
						<mat-icon class="mr-2 opacity50 fs-14" matPrefix>lock_open</mat-icon>
					</mat-form-field>
				<span class="error ml-1 ml-sm-3" *ngIf="formData.hasError('notSame') && formData.controls['password'].touched && formData.controls['confirmPassword'].touched && formType == [FormType.REGISTER]">{{ 'REGISTRATIONS.NO_MATCH_PASS' | translate }}</span>
			</form>
			<button [tabindex]="formType == [FormType.LOGIN] ? 3 : null" [disabled]="formData.status == 'VALID'" (click)="submitForm()" class="shortAnim outline-none mt-4 btn-login submit clickable" mat-raised-button>
				{{ buttonTextTranslationKey | translate }}
				<mat-icon class="shortAnim">chevron_right</mat-icon>
			</button>
			<p class="info smaller">
				<ng-container *ngIf="formType == [FormType.REGISTER]; else elseTemplate">
					{{ 'REGISTRATIONS.ALREADY_HAVE_AN_ACCOUNT' | translate }}? <a class="text-bolder text-danger" [routerLink]="['/login']">{{ 'REGISTRATIONS.SIGNIN' | translate }}</a>
				</ng-container>
				<ng-template #elseTemplate>
					<span *ngIf="formType == [FormType.LOGIN]" class="d-block">
						{{ 'REGISTRATIONS.NO_ACCOUNT_QUESTION' | translate }}? <a class="text-bolder text-danger" [routerLink]="['/register']">{{ 'REGISTRATIONS.REGISTER' | translate }}</a>
					</span>
					<span *ngIf="formType == [FormType.LOGIN]" class="d-block">
						<a [routerLink]="['/forgot-password']" class="clickable"> {{ 'REGISTRATIONS.FORGOT_PASSWORD' | translate }}?</a>
					</span>
				</ng-template>
			</p>
		</div>
	</div>

    <ul class="bg-bubbles">
		<li></li>
		<li></li>
		<li></li>
		<li></li>
		<li></li>
		<li></li>
		<li></li>
		<li></li>
		<li></li>
		<li></li>
	</ul>
</div>