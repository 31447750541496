import { BaseModelActive, BaseModel, DayOfWeek, Image, ValueLabel } from '../../../shared/base/base.models';

export class PointOfSale extends BaseModelActive {
    
    code?: string;
    name?: string;
    type?: PointOfSaleType | string;
    priceType?: PointOfSaleType | string;
    geoX?: number;
    geoY?: number;
    workingHoursFrom?: string;
    workingHoursTo?: string;
    quantityControl?: boolean = false;
    workingDays?: PointOfSaleWorkingDay[] | string = [];
}

export enum PointOfSaleType { 
    WHOLESALE, RETAIL, WEB
}

export enum PriceType {
    VAT_INCLUDED, VAT_EXCLUDED
}

export class PointOfSaleWorkingDay extends BaseModel {
    day: DayOfWeek | string;
}

export class OfferedProduct extends BaseModelActive {
    pointOfSale?: PointOfSale | string;
    product?: Product;
    regularPrice?: number;
    salePrice?: number;
    discountPercentage?: number;
    affectsMinimumOrderValue?: boolean = true;
    affectsRebateBase?: boolean = true;
    badges?: Badge[] = [];
    amountFrom?: number;
    quantityOnOrders?: number;
    additionalQuantity?: number = 0;
    quantityAvailable?: number;
    erpOfferUpdate?: boolean = false; // if true, moderator can change quantityOnStock
    quantityOnStock?: number;
    maxQuantityPerOrder?: number;
    saleDetails?: SaleDetails[] = [];
    quantityOnStockLimitOne?: number;
    quantityOnStockLimitTwo?: number;
}

export class SaleDetails {
    amountFrom?: number;
    discountPercentage?: number;
    salePrice?: number;
}

export class Badge extends BaseModel {
    mnemonic?: string;
    name?: string;
    image?: Image;
}

export class Product extends BaseModelActive {
    code?: string;
    name?: string;
    description?: string;
    productComposition?: string;
    productAssortment?: string | ProductClassification;
    productBrand?: string | ProductClassification;
    productCategory?: string | ProductClassification;
    productGroup?: string | ProductClassification;
    productMerchandiseGroup?: string | ProductClassification;
    template?: string | Template;
    productTemplateAttributes?: ProductTemplateAttribute[] = [];
    eanCodes?: ProductEANCode[] = [];
    images?: Image[] = [];
    unitOfMeasurement?: string | UnitOfMeasurement;
}

export class ProductTemplateAttribute extends BaseModel {
    templateAttribute?: string | TemplateAttribute;
    value?: string;
}

export class ProductEANCode extends BaseModel {
    eanCode?: string;
}

export class UnitOfMeasurement extends BaseModel {
    userId?: string;
    code?: string;
    name?: string;
    description?: string;
    increment?: number;
}

export class ProductClassification extends BaseModel {
    code?: string;
    name?: string;
    priority?: number = 0;
    parent?: ProductClassification | string;
}

export enum ProductClassificationType {
    ASSORTMENT, BRAND, CATEGORY, GROUP, MERCHANDISE_GROUP, POINT_OF_SALE
}

export class Template extends BaseModel {
    name?: string;
    templateAttributes?: TemplateAttribute[] = [];
}

export class TemplateAttribute extends BaseModel {
    template?: Template;
    name?: string;
    dataType?: TemplateAttributeDataType;
}

export enum TemplateAttributeDataType {
    INTEGER, DECIMAL, DATE, VARCHAR
}
export class ShopParams {
    sortType?: 'ASC' | 'DESC';
    sortParam?: string;
    page?: number;
    pageSize?: number;
    regularPriceFrom?: number;
    regularPriceTo?: number;
    searchMap?: SearchMap;
    productMerchandiseGroup?: string;
}

export class SearchMap {
    name?: string;
    pointOfSaleName?: string;
    pmgName?: string;
    code?: string;
}

export class StorageFilterParams {
    name?: string;
    productMerchandiseGroup?: ProductClassification;
    pointOfSale?: PointOfSale;
    priceFrom?: number;
    priceTo?: number;
    sortBy?: ValueLabel;
    page?: number;
    pageSize?: number;
}

export class SearchParams{
    searchMap?: FilterParams;
    sortParam?: string;
    page?: number;
    pageSize?: number;
    sortType?: string;    
    regularPriceFrom?: number;
    regularPriceTo?: number;
    productMerchandiseGroup?: string;
}

export enum SortType{
    ASC,DESC
}

export class QueryParams {
    public static readonly productMerchandise = 'merch';
    public static readonly productAssortments = 'assortment';
    public static readonly productBrands = 'brand';
    public static readonly productCategories = 'category';
    public static readonly search = 'search';
    public static readonly sortBy = 's';
    public static readonly sortDirection = 'd';
    public static readonly page = 'p';
    public static readonly pageSize = 'ps';
    public static readonly priceFrom = 'from';
    public static readonly priceTo = 'to';
    public static readonly pointOfSale = 'pos';
    public static readonly productAttributeId = 'templ';
}

export class FilterParams{
    name?: string;
    pointOfSaleName?: string;
    pmgName?: string;
    // productMerchandiseGroup?: string;
    productMerchandiseGroupCode?: string;
}

export class Filter {
    searchMap?: FilterParams;
    page?: number;
    pageSize?: number = 36;
    sortParam?: string;
    sortType?: string;    
    regularPriceFrom?: number;
    regularPriceTo?: number;
    pointOfSale?: string;
    productBrandCodes?: string[];
    templateAttributeIds?: string[];

    constructor(queryParams) {
        this.searchMap = new FilterParams();

        this.searchMap.name = queryParams ? (queryParams[QueryParams.search] ? queryParams[QueryParams.search]: null) : null;
        this.searchMap.productMerchandiseGroupCode = queryParams ? (queryParams[QueryParams.productMerchandise] ? queryParams[QueryParams.productMerchandise]: null) : null;
        this.pointOfSale = queryParams ? (queryParams[QueryParams.pointOfSale] ? queryParams[QueryParams.pointOfSale]: null) : null;

        this.sortParam = queryParams ? (queryParams[QueryParams.sortBy] ? queryParams[QueryParams.sortBy]: null) : null;
        this.sortType = queryParams ? (queryParams[QueryParams.sortDirection] ? queryParams[QueryParams.sortDirection]: null) : null;      

        this.page = queryParams ? (queryParams[QueryParams.page] ? queryParams[QueryParams.page]: 0) : 0;
        this.pageSize =  queryParams ? (queryParams[QueryParams.pageSize] ? queryParams[QueryParams.pageSize]: 36) : 36;

        this.regularPriceFrom = queryParams ? (queryParams[QueryParams.priceFrom] ? queryParams[QueryParams.priceFrom]: null) : null;
        this.regularPriceTo = queryParams ? (queryParams[QueryParams.priceTo] ? queryParams[QueryParams.priceTo]: null) : null;

        this.productBrandCodes = queryParams ? (queryParams[QueryParams.productBrands] ? queryParams[QueryParams.productBrands].split(','): null) : null;
        this.templateAttributeIds = queryParams ? (queryParams[QueryParams.productAttributeId] ? queryParams[QueryParams.productAttributeId].split(','): null) : null;
    }
}

export class ProductTemplateAttributeByMerch {
    productTemplateAttributeId: string;
    productTemplateAttributeValue: string;
    templateAttributeId: string;
    templateAttributeName: string;
}
